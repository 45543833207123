import React from 'react'
import styles from "./headlineContainer.module.css"
import Headline from '../components/headline'

function HeadlineContainer(props) {

  return (
    <div>
      <div className={styles.heroDetails}>
        <h1>
          <Headline
            headline={props.headline}
            animationToggle={props.animationToggle}
            class={styles.headline}
          />
        </h1>
      </div>

      <div className={`${styles.heroDetailsOutline} ${styles.heroDetails}`}>
        <h1>
          <Headline
            headline={props.headline}
            animationToggle={props.animationToggle}
            class={`${styles.headline} ${styles.outline}`}
          />
        </h1>
      </div>
    </div>
  )
}

export default HeadlineContainer
