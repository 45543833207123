import React, { Component } from 'react'
import { InView } from 'react-intersection-observer'
import Hero from '../components/hero'
import ArticlePreview from '../components/article-preview'

class Main extends Component {
  constructor() {
    super()
    this.state = {
      headline: '',
      initialToggle: false,
      articleHeight: null,
      visibleHeight: null,
      animationToggle: null,
    }
  }

  componentDidMount() {
    this.handleWindowHeight()

    window.addEventListener('resize', this.handleWindowHeight)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowHeight)
  }

  handleWindowHeight = () => {
    let article = document.getElementById('ovoid')
    let articleHeight = article.getBoundingClientRect().height
    let visibleHeight = window.innerHeight

    this.setState({
      articleHeight: articleHeight,
      visibleHeight: visibleHeight,
    })
  }

  render() {
    return (
      <main>
        <InView
          threshold={this.state.articleHeight > 1100 ? 0.5 : 0.5}
          as="div"
          onChange={(inView, entry) =>
            inView
              ? this.setState({
                  headline: this.props.author.node.title,
                  initialToggle: true,
                  animationToggle: true,
                })
              : this.setState({
                  initialToggle: false,
                  animationToggle: false,
                })
          }
        >
          <Hero
            data={this.props.author.node}
            headline={this.state.headline}
            animationToggle={this.state.animationToggle}
          />
        </InView>

        <div>
          <ul className="article-list">
            {this.props.posts.map(({ node }) => {
              return (
                <InView
                  threshold={
                    this.state.articleHeight > 1150 &&
                    this.state.visibleHeight > 500
                      ? 0.5
                      : this.state.articleHeight > 1150 &&
                        this.state.visibleHeight < 500
                      ? 0.25
                      : this.state.articleHeight > 1050 &&
                        this.state.visibleHeight > 500
                      ? 0.4
                      : this.state.articleHeight > 1050 &&
                        this.state.visibleHeight < 500
                      ? 0.25
                      : this.state.articleHeight > 850 &&
                        this.state.visibleHeight > 500
                      ? 0.5
                      : this.state.articleHeight > 850 &&
                        this.state.visibleHeight < 500
                      ? 0.25
                      : 0.5
                  }
                  as="li"
                  onChange={(inView, entry) =>
                    inView
                      ? this.setState({
                          headline: node.title,
                          animationToggle: true,
                        })
                      : this.state.initialToggle
                      ? null
                      : this.setState({
                          animationToggle: false,
                        })
                  }
                  key={node.slug}
                  className={node.slug}
                  id={`${node.slug}id`}
                >
                  <ArticlePreview
                    article={node}
                    linkClicked={this.props.linkClicked}
                    link={this.props.link}
                  />
                </InView>
              )
            })}
          </ul>
        </div>
      </main>
    )
  }
}

export default Main
