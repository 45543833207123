import React from 'react'
import Media from './media.js'
import styles from './article-preview.module.scss'
import { InView } from 'react-intersection-observer'
import { useSpring, animated } from 'react-spring'
import { ParallaxProvider, Parallax } from 'react-scroll-parallax'
import TransitionLink from 'gatsby-plugin-transition-link'

function ArticlePreview(props) {
  const flipDown = (imageId, inView) => {
    let container = document.getElementById(imageId)
    inView ? container.classList.add(styles.swingDown) : null
  }

  const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]

  const trans1 = (x, y) => `translate3d(${x / 15}px,${y / 20}px,0)`
  const trans2 = (x, y) => `translate3d(${x / 28}px,${y / 31}px,0)`
  const trans3 = (x, y) => `translate3d(${x / 20}px,${y / 23}px,0)`

  const [propsParallax, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }))

  return (
    <div
      className={`articles ${styles.preview}`}
      onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
    >
      <ParallaxProvider>
        <div>
          {!props.article.imageRight ? (
            <Parallax
              className={styles.heroContainerLarge}
              y={['-100px', '100px']}
              tagOuter="figure"
            >
              <animated.div
                style={{ transform: propsParallax.xy.interpolate(trans1) }}
              >
                <InView
                  as="div"
                  className={`${styles.imgContainer}`}
                  threshold="0.15"
                  id={props.article.slug}
                  onChange={(inView, entry) => {
                    flipDown(props.article.slug, inView)
                  }}
                >
                  <TransitionLink
                    to={`/project/${props.article.slug}/`}
                    exit={{ length: 0.6 }}
                    entry={{ delay: 0.8, length: 1 }}
                    state={{ fromMain: true }}
                  >
                    <Media
                      videoCheck={props.article.heroImage.file.url.slice(-3)}
                      videoSrcURL={props.article.heroImage.file.url}
                      styleClass={props.article.heroImage.title}
                      imgSizes={props.article.heroImage.sizes}
                    />
                  </TransitionLink>
                </InView>
              </animated.div>
            </Parallax>
          ) : (
            <Parallax
              className={styles.heroContainer}
              y={['-100px', '100px']}
              tagOuter="figure"
            >
              <animated.div
                style={{ transform: propsParallax.xy.interpolate(trans1) }}
              >
                <InView
                  as="div"
                  className={`${styles.imgContainer}`}
                  threshold="0.05"
                  id={props.article.slug}
                  onChange={(inView, entry) => {
                    flipDown(props.article.slug, inView)
                  }}
                >
                  <TransitionLink
                    to={`/project/${props.article.slug}/`}
                    exit={{ length: 0.6 }}
                    entry={{ delay: 0.8, length: 1 }}
                  >
                    <Media
                      videoCheck={props.article.heroImage.file.url.slice(-3)}
                      videoSrcURL={props.article.heroImage.file.url}
                      styleClass={props.article.heroImage.title}
                      imgSizes={props.article.heroImage.sizes}
                    />
                  </TransitionLink>
                </InView>
              </animated.div>
            </Parallax>
          )}
        </div>

        <div>
          {!props.article.imageLeft ? (
            <div />
          ) : (
            <Parallax
              className={styles.leftImageContainer}
              y={[-20, -80]}
              tagOuter="figure"
            >
              <animated.div
                style={{ transform: propsParallax.xy.interpolate(trans2) }}
              >
                <InView
                  as="div"
                  className={`${styles.imgContainer}`}
                  threshold="0.2"
                  id={`${props.article.slug}Left`}
                  onChange={(inView, entry) => {
                    flipDown(`${props.article.slug}Left`, inView)
                  }}
                >
                  <TransitionLink
                    to={`/project/${props.article.slug}/`}
                    exit={{ length: 0.6 }}
                    entry={{ delay: 0.8, length: 1 }}
                  >
                    <Media
                      videoCheck={props.article.imageLeft.file.url.slice(-3)}
                      videoSrcURL={props.article.imageLeft.file.url}
                      styleClass={props.article.imageLeft.title}
                      imgSizes={props.article.imageLeft.sizes}
                    />
                  </TransitionLink>
                </InView>
              </animated.div>
            </Parallax>
          )}
        </div>

        <div>
          {!props.article.imageRight ? (
            <div />
          ) : (
            <Parallax
              className={styles.rightImageContainer}
              y={[20, -120]}
              tagOuter="figure"
            >
              <animated.div style={{ transform: propsParallax.xy.interpolate(trans3) }}>
                <InView
                  as="div"
                  className={`${styles.imgContainer}`}
                  threshold="0.2"
                  id={`${props.article.slug}Right`}
                  onChange={(inView, entry) => {
                    flipDown(`${props.article.slug}Right`, inView)
                  }}
                >
                  <TransitionLink
                    to={`/project/${props.article.slug}/`}
                    exit={{ length: 0.6 }}
                    entry={{ delay: 0.8, length: 1 }}
                  >
                    <Media
                      videoCheck={props.article.imageRight.file.url.slice(-3)}
                      videoSrcURL={props.article.imageRight.file.url}
                      styleClass={props.article.imageRight.title}
                      imgSizes={props.article.imageRight.sizes}
                    />
                  </TransitionLink>
                </InView>
              </animated.div>
            </Parallax>
          )}
        </div>
      </ParallaxProvider>
    </div>
  )
}

export default ArticlePreview
