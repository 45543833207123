import React from 'react'
// import Link from 'gatsby-link'
import Link from 'gatsby-plugin-transition-link'
import styles from './footer.module.scss'

function Footer(props) {
  
  return (
    <footer id="footer">
        <div className={styles.topFooter}>
          <div className={styles.topFooterLeft}>
            <h3>About</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: props.footer.about.childMarkdownRemark.html,
              }}
            />
          </div>
  
          <div className={styles.topFooterRight}>
            <h3>Services</h3>
  
            <div
              dangerouslySetInnerHTML={{
                __html: props.footer.services.childMarkdownRemark.html,
              }}
            />
          </div>
        </div>
        <div className={styles.midFooter}>
          <div className={styles.midFooterLeft}>
            <h3>Clients</h3>
  
            <div
              dangerouslySetInnerHTML={{
                __html: props.footer.clients.childMarkdownRemark.html,
              }}
            />
          </div>
          <div className={styles.midFooterRight}>
            <div className={styles.links}>
              <h3>Social</h3>
              <ul className={styles.socialMedia}>
                <li className={styles.socialItem}>
                  <a
                    href="https://www.instagram.com/artefactcreative/"
                    target="_blank"
                    rel="noopener"
                  >
                    Instagram
                  </a>
                </li>
                <li className={styles.socialItem}>
                  <a
                    href="https://www.facebook.com/artefactcreativeto/"
                    target="_blank"
                    rel="noopener"
                  >
                    Facebook
                  </a>
                </li>
              </ul>
            </div>
            <div className={styles.inquiries}>
              <h3>General Inquiries</h3>
              <a href="mailto:info@artefactcreative.ca">
                info@artefactcreative.ca
              </a>
            </div>
          </div>
        </div>
        <div className={styles.bottomFooter}>
          <div>
            <a>{props.year} (C) Artefact Creative</a>
          </div>
          <div>
            <a href="https://konraddroeske.com" target="_blank" rel="noopener">
              Site by Konrad D
            </a>
          </div>
        </div>
    </footer>
  )
}

export default Footer
