import React from 'react'
import { useSpring, useTrail, animated as a, config } from 'react-spring'
import styles from './headlineContainer.module.css'

function Headline(props) {

  let items = props.headline.split("");

  const trail = useTrail(items.length, {
    config: {
      mass: 0.2,
      tension: 800,
      friction: 45,
    },
    opacity: 1,
    x: props.animationToggle ? 0 : 150,
    from: { opacity: 1, x: 150 },
  })

  const fade = useSpring({
    opacity: props.animationToggle ? 1 : 0,
    config: {
      mass: 1,
      tension: 70,
      friction: 100,
    },
  })

  return (
    <a.div className={props.class}>

      {trail.map(({ x, ...rest }, index) => (

        <a.span
          className="trails-text"
          style={{
            ...rest,
            transform: x.interpolate(x => `translate3d(0,${x}px,0)`),
          }}
          key={index}
        >

          {items[index] === ' ' ? (
            <div className={styles.space}></div>
          ) : (
            <div>{items[index]}</div>
          )}

        </a.span>

      ))}
    </a.div>
  )
}

export default Headline
