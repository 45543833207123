import React from 'react'
import Img from 'gatsby-image'
import { useSpring, animated } from 'react-spring'
import styles from './hero.module.scss'
import HeadlineContainer from '../components/headlineContainer'
import Media from './media.js'

function Hero({ data, headline, animationToggle }) {
  
  const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
  const trans1 = (x, y) => `translate3d(${x / 11}px,${y / 14}px,0)`
  const trans2 = (x, y) => `translate3d(${x / 28}px,${y / 31}px,0)`
  const trans3 = (x, y) => `translate3d(${x / 20}px,${y / 23}px,0)`

  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }))

  return (
    <div
      id="hero"
      className={styles.hero}
      onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
    >
      <HeadlineContainer
        headline={headline}
        animationToggle={animationToggle}
      />

      <animated.div
        className={styles.heroContainer}
        style={{ transform: props.xy.interpolate(trans1) }}
      >
        <div className={styles.swingDown}>
          <Media
            videoCheck={data.heroImage.file.url.slice(-3)}
            videoSrcURL={data.heroImage.file.url}
            styleClass={data.heroImage.title}
            imgAlt={data.name}
            imgSizes={data.heroImage.sizes}
          />
        </div>
      </animated.div>

      <animated.div
        className={styles.leftContainer}
        style={{ transform: props.xy.interpolate(trans2) }}
      >
        <div className={styles.swingDown}>
          <Media
            videoCheck={data.imageLeft.file.url.slice(-3)}
            videoSrcURL={data.imageLeft.file.url}
            styleClass={data.imageLeft.title}
            imgAlt={data.name}
            imgSizes={data.imageLeft.sizes}
          />
        </div>
      </animated.div>

      <animated.div
        className={styles.rightContainer}
        style={{ transform: props.xy.interpolate(trans3) }}
      >
        <div className={styles.swingDown}>
          <Media
            videoCheck={data.imageRight.file.url.slice(-3)}
            videoSrcURL={data.imageRight.file.url}
            styleClass={data.imageRight.title}
            imgAlt={data.name}
            imgSizes={data.imageRight.sizes}
          />
        </div>
      </animated.div>
    </div>
  )
}

export default Hero
