import React, { Component } from 'react'
import get from 'lodash/get'
import Layout from '../layouts/layout.js'
import Main from '../components/main'
import Footer from '../components/footer'
import Helmet from 'react-helmet'
import Transitioner from '../components/transition'
import Navigation from '../components/navigation'

class RootIndex extends Component {
  constructor() {
    super()
    this.state = {
      year: '',
      mainPage: '',
      hash: '',
    }
  }

  componentDidMount() {
    let yearNow = new Date().getFullYear()

    this.setState({
      year: yearNow,
      mainPage: true,
      hash: this.props.location.hash,
    })
  }

  componentWillUnmount() {
    this.setState({
      mainPage: false,
    })
  }

  render() {
    const posts = get(this, 'props.data.allContentfulBlogPost.edges')
    const [author] = get(this, 'props.data.allContentfulPerson.edges')
    const footer = get(this, 'props.data.allContentfulFooter.edges')

    return (
      <div className="background">
        <Transitioner location={this.state.hash}>
          <Layout>
            <Helmet>
              {/* <title>Artefact Creative</title> */}
            </Helmet>

            <Navigation mainPage={this.state.mainPage} />
            <Main author={author} posts={posts} />
            <Footer footer={footer[0].node} year={this.state.year} />
          </Layout>
        </Transitioner>
      </div>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage: heroImage {
            sizes(maxWidth: 1000, resizingBehavior: PAD) {
              ...GatsbyContentfulSizes_withWebp
            }
            file {
              contentType
              url
            }
            title
          }
          imageLeft: imageLeft {
            sizes(maxWidth: 1000, resizingBehavior: PAD) {
              ...GatsbyContentfulSizes_withWebp
            }
            file {
              contentType
              url
            }
            title
          }
          imageRight: imageRight {
            sizes(maxWidth: 1000, resizingBehavior: PAD) {
              ...GatsbyContentfulSizes_withWebp
            }
            file {
              contentType
              url
            }
            title
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }

    allContentfulPerson(
      filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
    ) {
      edges {
        node {
          name
          shortBio {
            shortBio
          }
          title
          heroImage: image {
            sizes(maxWidth: 1000, resizingBehavior: PAD) {
              ...GatsbyContentfulSizes_withWebp
            }
            file {
              contentType
              url
            }
            title
          }
          imageLeft: imageLeft {
            sizes(maxWidth: 1000, resizingBehavior: PAD) {
              ...GatsbyContentfulSizes_withWebp
            }
            file {
              contentType
              url
            }
            title
          }
          imageRight: imageRight {
            sizes(maxWidth: 1000, resizingBehavior: PAD) {
              ...GatsbyContentfulSizes_withWebp
            }
            file {
              contentType
              url
            }
            title
          }
        }
      }
    }

    allContentfulFooter(
      filter: { contentful_id: { eq: "1QQnyjezUhhisygov3Rxh4" } }
    ) {
      edges {
        node {
          title
          clients {
            childMarkdownRemark {
              html
            }
          }
          services {
            childMarkdownRemark {
              html
            }
          }
          about {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
